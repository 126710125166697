<template>
  <div class="alert-container">
    <a-alert
      v-if="formUploadChanged && enumerators && enumerators.length > 0"
      class="sticky-alert"
      :description="uploadQuestionnaireMessage"
      type="info"
      closable
      :after-close="closeFormUploadAlert"
      show-icon/>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import enumerators from '../../mixins/enumerators';
import { i18n } from '../../localization';

export default {
  name: 'FormUploadAlert',
  mixins: [enumerators()],
  data() {
    return {
      uploadQuestionnaireMessage: i18n.t('components.description.theQuestionnaireTemplateHasBeenChanged')
    };
  },
  computed: {
    ...mapState({
      formUploadChanged: state => state.surveyForms.formUploadChanged
    })
  },
  methods: {
    ...mapActions([
      'ackFormUploadAlert'
    ]),
    closeFormUploadAlert() {
      this.ackFormUploadAlert();
    }
  }
};
</script>
<style lang="scss" scoped>

.sticky-alert {
  margin-top: 10px;
  align-content: center;
  min-width: 70%;
  width: auto;
  max-width: 100%;
  margin-right: 24pt;
}

.alert-container {
  position: fixed;
  z-index: 9999;
  width: auto;
}
</style>
